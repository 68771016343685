export const onePnInKg = 2.2046226218;
const inchesInFt = 12;
const oneFtInCm = 30.48;
const oneInchInCm = 2.54;

export const roundToTwoDecimalPlaces = (value: number): number => {
  if (!value) return null;

  const numberValue = Number(value);

  return parseFloat(numberValue.toFixed(2));
};

export const covertCmToFt = (value: number): [number, number] => {
  if (value === null) return [null, null];

  if (value < 122) {
    return [4, 0];
  } else if (value > 241) {
    return [7, 11];
  }
  const length = value / oneInchInCm;
  const feet = Math.floor(length / inchesInFt);
  const inch = length - inchesInFt * feet;

  if (inch >= 11.5) {
    return [feet + 1, 0];
  }

  return [feet, inch];
};

export const convertFtToCm = (
  feet: number | null | undefined,
  inch: number | null | undefined
): number => {
  // if either feet or inch are undefined (migrated accounts)
  // or if feet AND inch are falsey (null, 0, undefined)
  // have to handle case where inch can be 0
  if (feet === undefined || inch === undefined || (!feet && !inch)) return null;

  if (feet === 4 && inch === 0) {
    return 122;
  }

  return feet * oneFtInCm + inch * oneInchInCm;
};

export const convertFtToInches = (feet: number, inch: number): number => {
  if (feet === null && inch === null) return null;

  return Number((feet * inchesInFt + Number(inch)).toFixed(2));
};

export const convertInchesToFt = (
  value: number | null
): { feet: number; inch: number } => {
  if (!value) return { feet: null, inch: null };
  return { feet: ~~(value / inchesInFt), inch: value % 12 };
};
