import * as React from 'react';
import { useCallback, useContext, useEffect, useState } from 'react';
import { getUser } from '@/api';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, ContentBlock, Loader, Typography } from '@/components';
import { CheckboxField, Flex, Grid, View } from '@aws-amplify/ui-react';
import { Auth } from 'aws-amplify';
import { useStyles } from './styles';
import {
  AlertContext,
  AssetsContext,
  InIframeContext
} from '@/GlobalProvider/GlobalProvider';
import {
  formatDateWithTime,
  formatPhoneNumber,
  getFilteredScans,
  getSuccessfulScan,
  useIframeMessageHandler
} from '@/utils';
import { CustomerAccounts, ScanStatus } from '@/constants';
import { RefreshButton } from '@/components/RefreshButton';
import { QRCodeButton } from '@/components/QRCodeButton';
import { CustomerQRCode } from '@/components/CustomerQRCode';
import { SizeRecommendations } from '@/components/SizeRecommendations';
import { ScanMeasurements } from '@/components/ScanMeasurements';

export enum AdditionalInfoStates {
  QRCode = 'QR Code',
  SizeRecommendations = 'Size Recommendations',
  ScanMeasurements = 'Scan Measurements'
}

const checkHAWGValid = (currentCustomer) => {
  const { height, dateOfBirth, weight, gender } = currentCustomer || {};

  return !!height && !!dateOfBirth && !!weight && !!gender;
};

export const Customer = () => {
  const { setAlert } = useContext(AlertContext);
  const inIframe = useContext(InIframeContext);
  const {
    asset_list: { confirm_deposit_url },
    behavior_list: {
      configurator_url,
      configurator_eventid_lookup_list,
      customer_account_handle,
      confirm_deposit_checkbox,
      attendant_flow,
      rtw_measures,
      fit_preference_url
    }
  } = useContext(AssetsContext);
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [currentCustomer, setCurrentCustomer] = useState(null);
  const [previousScan, setPreviousScan] = useState(null);
  const [successfulScan, setSuccessfulScan] = useState(null);
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [additionalInfoState, setAdditionalInfoState] =
    useState<AdditionalInfoStates | null>(null);
  const [isAdditionalInfoFullScreen, setIsAdditionalInfoFullScreen] =
    useState<boolean>(false);
  const styles = useStyles();
  const navigate = useNavigate();

  const [shopLink, setShopLink] = useState(configurator_url);
  const [confirmDepositValue, setConfirmDepositValue] = useState(false);

  const isObjectEmpty = (obj) => {
    if (obj && typeof obj === 'object' && obj.constructor === Object) {
      return Object.keys(obj).length === 0;
    }
    return true;
  };

  const isPreviousScanComplete =
    previousScan !== null && previousScan.status === ScanStatus.Complete;

  const getScans = useCallback(async (customerData: any) => {
    setRefreshLoading(true);
    const filteredScansData = await getFilteredScans(params.customerId);
    const successfulScanData = getSuccessfulScan(filteredScansData);

    if (filteredScansData.length > 0) {
      // loop through scans and find the first complete scan
      for (let i = 0; i < filteredScansData.length; i++) {
        if (filteredScansData[i].status === ScanStatus.Complete) {
          setPreviousScan(filteredScansData[i]);
          break;
        }
      }
    }

    if (shopLink) {
      if (successfulScanData) {
        setSuccessfulScan(successfulScanData);
      }
      const user = await Auth.currentUserInfo();
      const email = user?.attributes.email;
      const eventId = configurator_eventid_lookup_list[email];

      let completeLink = shopLink
        .replace('{emailAddress}', encodeURIComponent(customerData.email))
        .replace('{userid}', customerData.id)
        .replace('{eventId}', eventId);

      // if HAWG is not valid, then this is a legacy account
      // pass through the placeholder "legacy_scan" for scan id
      if (!checkHAWGValid(customerData)) {
        completeLink = completeLink.replace('{scanId}', 'legacy_scan');
      } else if (filteredScansData[0]) {
        completeLink = completeLink.replace(
          '{scanId}',
          filteredScansData[0].id
        );
      } else {
        completeLink = completeLink.replace('&scan_id={scanId}', '');
      }

      if (attendant_flow) {
        const attendantToken = window.localStorage.getItem('attendantToken');
        completeLink = completeLink.replace('{attendantToken}', attendantToken);
      } else {
        completeLink = completeLink.replace('&token={attendantToken}', '');
      }

      setShopLink(completeLink);
    }
    setRefreshLoading(false);
  }, []);

  useIframeMessageHandler(currentCustomer, successfulScan?.id);

  const contentChangeHandler = (state: AdditionalInfoStates | null) => {
    switch (state) {
      case AdditionalInfoStates.QRCode:
        return <CustomerQRCode currentCustomer={currentCustomer} />;
      case AdditionalInfoStates.SizeRecommendations:
        return (
          <SizeRecommendations
            scanId={previousScan?.id}
            setFullScreen={setIsAdditionalInfoFullScreen}
          />
        );
      case AdditionalInfoStates.ScanMeasurements:
        return <ScanMeasurements />;
      default:
        return null;
    }
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      const customerData = await getUser(params.customerId);
      if (customerData.status) {
        if (customerData.status === 403) {
          setAlert({
            text: 'This account is not sharing to your company.',
            variation: 'error'
          });
        } else if (customerData.status === 404) {
          setAlert({
            text: 'This account was not found.',
            variation: 'error'
          });
        }
      } else {
        await getScans(customerData);
        setCurrentCustomer(customerData);
      }
      setLoading(false);
    })();
  }, []);

  useEffect(() => {
    let interval;

    if (!loading) {
      interval = setInterval(() => {
        getScans(currentCustomer);
      }, 60000);
    }

    return () => clearInterval(interval);
  }, [loading]);

  if (loading) {
    return <Loader />;
  }

  const confirmDepositLabel = (
    <div className={styles.confirmDepositLabel}>
      Accept{' '}
      <a target="_blank" href={confirm_deposit_url} rel="noreferrer">
        Made to Measure Deposit*
      </a>
    </div>
  );

  if (!currentCustomer) return null;

  return (
    <ContentBlock
      additionalInfoLargeSize={isAdditionalInfoFullScreen}
      hasBackButton
      showAdditionalInfo={!!additionalInfoState}
      hideAdditionalInfo={(v) => {
        setAdditionalInfoState(v);
        setIsAdditionalInfoFullScreen(false);
      }}
      additionalInfoTitle={additionalInfoState}
      additionalInfo={contentChangeHandler(additionalInfoState)}
    >
      <Grid templateColumns="1fr" columnGap="3rem" className={styles.customer}>
        <View columnSpan={[2, 2, 1]} className={styles.content}>
          <Flex className={styles.customerHeader}>
            <RefreshButton
              onClick={() => getScans(currentCustomer)}
              loading={refreshLoading}
            />
            <QRCodeButton
              style={{ marginLeft: 10 }}
              open={additionalInfoState === AdditionalInfoStates.QRCode}
              onClick={setAdditionalInfoState}
            />
          </Flex>
          <Typography component="p">Welcome</Typography>
          <Typography component="h2" className={styles.title}>
            {currentCustomer.firstName} {currentCustomer.lastName}
          </Typography>
          <div>
            <Button
              text="Edit Profile"
              buttonType="dark"
              size={'small'}
              className={styles.button}
              onClick={() =>
                navigate(
                  `/createCustomer/${params.customerId}/${params.accountId}`
                )
              }
            />
          </div>
          <div className={styles.personalInfo}>
            {customer_account_handle ===
            CustomerAccounts.ThirdPartyAccountId ? (
              <Typography component="p" className={styles.subtitle}>
                <span>Subject ID:</span> {decodeURI(params.accountId)}
              </Typography>
            ) : (
              <>
                <Typography component="p" className={styles.subtitle}>
                  <span>Phone:</span>{' '}
                  {currentCustomer.phone
                    ? formatPhoneNumber(currentCustomer.phone)
                    : '-'}
                </Typography>
                <Typography component="p" className={styles.subtitle}>
                  <span>Email:</span> {currentCustomer.email || '-'}
                </Typography>
              </>
            )}
          </div>
          <div className={styles.actionBlock}>
            <Button
              text={
                <>
                  <span className={styles.desktopText}>
                    {AdditionalInfoStates.ScanMeasurements}
                  </span>
                  <span className={styles.mobileText}>Measurements</span>
                </>
              }
              buttonType={
                additionalInfoState === AdditionalInfoStates.ScanMeasurements
                  ? 'secondary'
                  : 'dark'
              }
              size={'small'}
              className={styles.button}
              onClick={() =>
                setAdditionalInfoState(AdditionalInfoStates.ScanMeasurements)
              }
            />
            <>
              {!isObjectEmpty(rtw_measures) && (
                <Button
                  text={
                    <>
                      <span className={styles.desktopText}>
                        {AdditionalInfoStates.SizeRecommendations}
                      </span>
                      <span className={styles.mobileText}>Recommendations</span>
                    </>
                  }
                  buttonType={
                    additionalInfoState ===
                    AdditionalInfoStates.SizeRecommendations
                      ? 'secondary'
                      : 'dark'
                  }
                  size={'small'}
                  className={styles.button}
                  onClick={() =>
                    setAdditionalInfoState(
                      AdditionalInfoStates.SizeRecommendations
                    )
                  }
                  isDisabled={!isPreviousScanComplete}
                />
              )}
              {fit_preference_url && (
                <Button
                  style={{ width: '46%' }}
                  text={'Fit Preferences'}
                  buttonType={'dark'}
                  size={'small'}
                  className={styles.button}
                  onClick={() =>
                    window.open(
                      `${fit_preference_url}&firstName=${currentCustomer.firstName}&lastName=${currentCustomer.lastName}&email=${currentCustomer.email}&user_id=${currentCustomer.id}`,
                      '_blank'
                    )
                  }
                />
              )}
            </>
          </div>
          {previousScan && (
            <div className={styles.previousScan}>
              <Typography component="p" className={styles.scanBlockTitle}>
                <span>Previous Scan Details:</span>
              </Typography>
              <div className={styles.scan}>
                <Typography component="p" className={styles.scanDetails}>
                  <span>Date:</span> {formatDateWithTime(previousScan.date)}
                </Typography>
                <Typography component="p" className={styles.scanDetails}>
                  <span>Status:</span>{' '}
                  {previousScan.status === ScanStatus.Complete
                    ? 'Complete'
                    : previousScan.status === ScanStatus.Failed
                    ? 'Failed'
                    : previousScan.status === ScanStatus.WaitingForData
                    ? 'Waiting for Data'
                    : 'Processing'}
                </Typography>
              </div>
            </div>
          )}
        </View>
      </Grid>
      <div className={styles.buttonsContainer}>
        {shopLink && !inIframe && (
          <div className={styles.continueShoppingButton}>
            {confirm_deposit_checkbox === 'true' && (
              <div className={`${styles.confirmDeposit} custom-checkbox`}>
                <CheckboxField
                  value=""
                  label={confirmDepositLabel}
                  name="confirmDeposit"
                  onChange={(e) => setConfirmDepositValue(e.target.checked)}
                />
              </div>
            )}
            <Button
              buttonType="secondary"
              isDisabled={
                confirm_deposit_checkbox === 'true' && !confirmDepositValue
              }
              text="Go to Shop"
              onClick={() => {
                if (currentCustomer.firstName && currentCustomer.lastName) {
                  window.open(
                    `${shopLink}&first_name=${currentCustomer.firstName}&last_name=${currentCustomer.lastName}`
                  );
                } else {
                  window.open(shopLink);
                }
              }}
            />
          </div>
        )}
      </div>
    </ContentBlock>
  );
};
